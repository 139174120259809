import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Redirect } from "react-router-dom";
import { API_SERVER } from '../Api/Server.js';
import { withStyles } from "@material-ui/core/styles";
import EitlabLoader from '../Api/EitlabLoader';
import axios from 'axios';
const logo = process.env.PUBLIC_URL + 'logo.png';

const styles = theme => ({
  root: {
    height: '100vh',
    background: 'rgba(3,152,158,.1)',
  },
  image: {

  },
  mt: {
    marginTop: '20px',

  },
  paper: {
    // margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '32px 32px 64px 32px',
  },
  avatar: {
    margin: theme.spacing(1),

  },
  logo: {
    height: '70px!important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  a: {
    color: '#03989e!important'
  },
  bg: {
    background: '#03989e!important'
  }
});
class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password_confirmation: '',
      error_password: '',
      error_password_confirmation: '',
      bool_password: false,
      bool_password_confirmation: false,
      isl: false,
      btn_disabled: false,
      ril: false,
      g_loader: true,
      redirect: '',
    }
  }

  password_reset = (e) => {
    e.preventDefault();
    if (this.state.password === '') { this.setState({ error_password: 'Saisissez votre mot de passe.', bool_password: true }) }
    if (this.state.password_confirmation === '') { this.setState({ error_password_confirmation: 'Confirmer le mot de passe.', bool_password_confirmation: true }) }
    if (this.state.password === this.state.password_confirmation) { this.setState({ error_password_confirmation: '' }) }
    else { this.setState({ error_password_confirmation: 'Vos mots de passe sont différents.' }); }

    if (this.state.password !== '' && this.state.password_confirmation !== '') {
      this.setState({ btn_disabled: true });
      const query = new URLSearchParams(this.props.location.search);
      let mail_token = query.get('m_t');
      let email = query.get('email');
      axios.post(API_SERVER + 'password/reset?m_t=' + mail_token + '&email=' + email, {
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      })
        .then(response => {
          if (response.data.status === "success") {
            NotificationManager.success(response.data.data.message, 'Opération réussie');
            setTimeout(() => {
              this.setState({ redirect: '/login' });
            }, 3000);
          }

          if (response.data.status === "error") {
            NotificationManager.error(response.data.data.message, 'Erreur lors de la demande!');
          }

          this.setState({ btn_disabled: false });

        })
        .catch((error) => {
          this.setState({ btn_disabled: false });
          NotificationManager.error('Votre adresse email n\'existe pas.', 'Réesayer!');

        });
    }
  }

  handleChangePassord = (val) => {
    if (val.target.value === '') {
      this.setState({ error_password: 'Définissez un mot de passe.', bool_password: true, password: '' });
    }
    else {
      this.setState({ password: val.target.value, bool_password: false, error_password: '' });
    }
  }
  handleChangePasswordConfirmation = (val) => {
    if (val.target.value === '') {
      this.setState({ error_password_confirmation: 'Confirmer le mot de passe.', bool_password_confirmation: true, password_confirmation: '' });
    }
    else {
      this.setState({ password_confirmation: val.target.value, bool_password_confirmation: false, error_password_confirmation: '' });
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let mail_token = query.get('m_t');
    let email = query.get('email');
    axios.get(API_SERVER + 'password/reset?m_t=' + mail_token + '&email=' + email, {
    })
      .then(response => {
        if (response.data.status === "success") {
          if (!response.data.data.validate) {
            window.location.href = "/login"
          }
          if (response.data.data.validate) {
            this.setState({ g_loader: false })
          }
        }
        else {
          window.location.href = "/login"
        }

      })
      .catch((error) => {
        window.location.href = "/login"
      });
  }
  render() {
    const classes = this.props.classes;
    document.title = "Réinitialisation de mot de passe - EXPERT IT LAB";
    if (this.state.redirect !== '') {
      return <Redirect to={this.state.redirect} />
    }
    if (this.state.g_loader) {
      return (

        <div style={{ textAlign: 'center', paddingTop: '50vh', height: '100vh', }}>
          <EitlabLoader loader_color="#03989e" />
        </div>
      )

    } else {


      return (

        <Grid container component="main" className={' justify-content-md-center'}>
          <CssBaseline />
          <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square className="pt-only-lg">
            <div className={classes.paper}>
              <div className={''}>
                <Link to="/">
                  <img src={'/logo.png'} height={'50px'} alt="EXPERT IT LAB" className={logo} />
                </Link>
              </div>
              <Typography component="h1" variant="h6">
                Récupération de mot de passe
            </Typography>

              <form className={classes.form} noValidate onSubmit={this.password_reset}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Nouveau mot de passe"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={this.state.bool_password}
                  helperText={this.state.error_password}
                  onChange={(val) => this.handleChangePassord(val)}
                  value={this.state.password}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password_confirmation"
                  label="Confirmer le mot de passe"
                  type="password"
                  id="password_confirmation"
                  autoComplete="password-confirmation"
                  error={this.state.bool_password_confirmation}
                  helperText={this.state.error_password_confirmation}
                  onChange={(val) => this.handleChangePasswordConfirmation(val)}
                  value={this.state.password_confirmation}
                />
                <br />
                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit + classes.bg}
                  id="register_btn"
                  disabled={this.state.btn_disabled}
                >
                  Valider
              </Button>
                <Grid container >

                  <Grid item className={classes.mt} align="center">
                    <Link to="/register" variant="body2" className={classes.a} align="center">
                      {"Vous n'avez pas de compte? Inscrivez-vous"}
                    </Link>
                    <br />
                    <Link to="/login" variant="body2" className={classes.a}>
                      {"Se connecter?"}
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Typography variant="body2" color="textSecondary" align="center">
                    L'expertisme numérique à votre portée ! <br />
                    {'Copyright © '}
                    <a href="https://expertitlab.com">
                      EXPERT IT LAB
                </a>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                  </Typography>
                </Box>
              </form>
            </div>
            <Grid item xs={false} sm={4} md={2} className={''} />
            <Grid item xs={false} sm={4} md={5} className={''} />
          </Grid>
          <NotificationContainer />
        </Grid>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(PasswordReset);