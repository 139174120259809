import Cookies from 'universal-cookie';
export const cookies = new Cookies();
export const EITLAB_TOKEN = cookies.get("eitlab_tk");
export const EITLAB = cookies.get("eitlab");
export const LOGIN_TOKEN = cookies.get("token");
export const COOKIES_PATH = '/';

//  Local
// export const ADMIN_URL = 'http://localhost:3001/';
// export const API_SERVER = 'http://localhost:8000/api/v1/';
// export const COOKIES_DOMAIN = 'localhost';

// Prod 
export const ADMIN_URL = 'https://admin.expertitlab.com/';
export const API_SERVER = 'https://api.expertitlab.com/api/v1/';
export const COOKIES_DOMAIN = '.expertitlab.com';

