import React from 'react';
import {  Route, Switch} from 'react-router-dom';

import Error from './views/Error/Index';
import Login from './views/Login/Login';
import PasswordReset from './views/Login/PasswordReset';
import ValidationCode from './views/Login/ValidationCode';
import EmailRequest from './views/Login/EmailRequest';
import Register from './views/Register/Register';
import NotFound from './views/NotFound/NotFound';


const Main = props =>(
  <Switch>
    <Route exact path='/' component={Login}/>
    <Route exact path='/login' component={Login} />
    <Route exact path='/register' component={Register} />
    <Route exact path='/password/request' component={EmailRequest} />
    <Route exact path='/password/reset' component={PasswordReset} />
    <Route exact path='/confirm/validation-code/:token@:eitlab_id' component={ValidationCode}/>
    <Route exact path='/errors' component={Error} />
    <Route  component={NotFound} />
  </Switch>

)
export default Main;
