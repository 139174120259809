import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import {
  API_SERVER, EITLAB_TOKEN, ADMIN_URL, COOKIES_PATH, COOKIES_DOMAIN
} from '../Api/Server.js';
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import { withStyles } from "@material-ui/core/styles";
import EitlabLoader from '../Api/EitlabLoader';

const cookies = new Cookies();
const logo = process.env.PUBLIC_URL + 'logo.png';
const styles = theme => ({
  root: {
    height: '100vh',
    background: 'rgba(3,152,158,.1)',
  },
  image: {

  },
  mt: {
    marginTop: '20px',

  },
  paper: {
    // margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '15px 32px 64px 32px',
  },
  avatar: {
    margin: theme.spacing(1),

  },
  logo: {
    height: '70px!important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  a: {
    color: '#03989e!important'
  },
  bg: {
    background: '#03989e!important'
  }
});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      email: '',
      password: '',
      password_confirmation: '',
      error_fullname: '',
      error_email: '',
      error_password: '',
      error_password_confirmation: '',
      bool_fullname: false,
      bool_email: false,
      bool_password: false,
      bool_password_confirmation: false,
      isl: false,
      btn_disabled: false,
      ReCAPTCHA: false,
      step: '',
      confirm_code_url: '',
      redirect: false,
      g_loader: true

    }
  }
  componentDidMount() {
    axios.get(`${API_SERVER + 'get-user-step?e='}` + EITLAB_TOKEN)
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.data.step === "LOGIN") { }
          if (res.data.data.step === "CONFIRM_ACCOUNT") {
            this.setState({ g_loader: false, step: res.data.data.step, confirm_code_url: '/confirm/validation-code/' + res.data.data.eitlab_t + '@' + res.data.data.eitlab, redirect: true })
          }
          if (res.data.data.step === "IS_LOGGED") {
            window.location.href = ADMIN_URL;
          }

        }
        this.setState({ g_loader: false })
      })
  }
  register = (e) => {
    e.preventDefault();
    if (this.state.fullname === '') { this.setState({ error_fullname: 'Saisissez votre nom et prénom.', bool_fullname: true }) }
    if (this.state.email === '') { this.setState({ error_email: 'Saisissez votre email.', bool_email: true }) }
    if (this.state.password === '') { this.setState({ error_password: 'Saisissez votre mot de passe.', bool_password: true }) }
    if (this.state.password_confirmation === '') { this.setState({ error_password_confirmation: 'Confirmer le mot de passe.', bool_password_confirmation: true }) }

    if (this.state.password === this.state.password_confirmation) { this.setState({ error_password_confirmation: '' }) }
    else { this.setState({ error_password_confirmation: 'Vos mots de passe sont différents.' }); }

    if (this.state.fullname !== '' && this.state.email !== '' && this.state.password !== '' && this.state.password_confirmation !== '' && this.state.password === this.state.password_confirmation
      && this.state.ReCAPTCHA !== false && this.state.ReCAPTCHA !== null
    ) {
      this.setState({ btn_disabled: true });
      axios.post(API_SERVER + 'register', {
        fullname: this.state.fullname,
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      })
        .then(response => {
          if (response.data.status === "success") {
            cookies.set('eitlab', response.data.data.eitlab_id, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
            cookies.set('eitlab_isl', false, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
            cookies.set('eitlab_tk', response.data.data.token, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
            window.location.href = '/confirm/validation-code/' + response.data.data.token + '@' + response.data.data.eitlab_id;
            this.setState({ step: response.data.data.step, confirm_code_url: '/confirm/validation-code/' + response.data.data.token + '@' + response.data.data.eitlab_id, redirect: true })

          }
          else {

            if (response.data.status === "echec") {
              let errors = response.data.errors.email[0];
              NotificationManager.error(errors, 'Erreur lors de l\'inscription!');
            }
          }
          this.setState({ btn_disabled: false });

        })
        .catch(error => {
          this.setState({ btn_disabled: false });

          NotificationManager.error('Erreur interne', 'Fermer!');

        });
    }
  }
  handleChangeFullname = (val) => {

    if (val.target.value === '') {
      this.setState({
        error_fullname: 'Saisissez votre nom et prénom.',
        bool_fullname: true,
        fullname: '',

      });
    }
    else {
      this.setState({ fullname: val.target.value, bool_fullname: false, error_fullname: '' });
    }
  }
  handleChangeEmail = (val) => {
    if (val.target.value === '') {
      this.setState({ error_email: 'Saisissez votre adresse email.', bool_email: true, email: '' });
    }
    else {
      this.setState({ email: val.target.value, bool_email: false, error_email: '' });
    }
  }
  handleChangePassord = (val) => {
    if (val.target.value === '') {
      this.setState({ error_password: 'Définissez un mot de passe.', bool_password: true, password: '' });
    }
    else {
      this.setState({ password: val.target.value, bool_password: false, error_password: '' });
    }
  }
  handleChangePasswordConfirmation = (val) => {
    if (val.target.value === '') {
      this.setState({ error_password_confirmation: 'Confirmer le mot de passe.', bool_password_confirmation: true, password_confirmation: '' });
    }
    else {
      this.setState({ password_confirmation: val.target.value, bool_password_confirmation: false, error_password_confirmation: '' });
    }
  }
  handleChangeRecaptcha = (value) => {
    this.setState({ ReCAPTCHA: value });
  }
  render() {
    const classes = this.props.classes;
    document.title = "Création de compte, EXPERT IT LAB";
    if (this.state.redirect === true) {
      return <Redirect to={this.state.confirm_code_url} />
    }
    if (this.state.g_loader) {
      return (

        <div style={{ textAlign: 'center', paddingTop: '50vh', height: '100vh', }}>
          <EitlabLoader loader_color="#03989e" />
        </div>
      )

    }
    else {
      return (
        <Grid container component="main" className={' justify-content-md-center'}>
          <CssBaseline />
          <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square className="pt-only-lg">
            <div className={classes.paper}>
              <div className={''}>
                <Link to="/">
                  <img src={logo} height={'50px'} alt="EXPERT IT LAB" className={logo} />
                </Link>
              </div>
              <Typography component="h1" variant="h5">
                Créer un compte
            </Typography>

              <form className={classes.form} noValidate onSubmit={this.register}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="fullname"
                  label="Nom et prénom"
                  name="fullname"
                  autoComplete="fullname"
                  autoFocus
                  required={true}
                  error={this.state.bool_fullname}
                  helperText={this.state.error_fullname}
                  onChange={(val) => this.handleChangeFullname(val)}
                  value={this.state.fullname}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Votre adresse email"
                  name="email"
                  autoComplete="email"
                  error={this.state.bool_email}
                  helperText={this.state.error_email}
                  onChange={(val) => this.handleChangeEmail(val)}
                  value={this.state.email}

                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={this.state.bool_password}
                  helperText={this.state.error_password}
                  onChange={(val) => this.handleChangePassord(val)}
                  value={this.state.password}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password_confirmation"
                  label="Confirmer le mot de passe"
                  type="password"
                  id="password_confirmation"
                  autoComplete="password-confirmation"
                  error={this.state.bool_password_confirmation}
                  helperText={this.state.error_password_confirmation}
                  onChange={(val) => this.handleChangePasswordConfirmation(val)}
                  value={this.state.password_confirmation}
                />
                <ReCAPTCHA
                  sitekey="6LcZwM0ZAAAAAMn0i5nS6rdH6hgfi3FYqD1A2s8b"
                  onChange={this.handleChangeRecaptcha}
                />
                <br />
                <FormControlLabel
                  control={<Checkbox value="remember" className={classes.a} checked={true} required />}
                  label="J'accepte les condtions d'utilisation" disabled

                />


                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit + classes.bg}
                  id="register_btn"
                  disabled={this.state.btn_disabled}
                >
                  S'inscrire
              </Button>
                <Grid container >
                  <Grid item className={classes.mt}>
                    <Link to="/login" variant="body2" className={classes.a}>
                      {"Vous avez un compte? Connectez-vous"}
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <p className="text-center"> 
                    <a href="https://expertitlab.com/fr/terms-of-use" target='_blank' rel="noreferrer">
                      Lire les conditions ?
                    </a>
                  </p>

                  <Typography variant="body2" color="textSecondary" align="center">
                    L'expertisme numérique à votre portée ! <br />
                    {'Copyright © '}
                    <a href="https://expertitlab.com" target='_blank' rel="noreferrer">
                      EXPERT IT LAB
                </a>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                  </Typography>
                </Box>
              </form>
            </div>
            <Grid item xs={false} sm={4} md={2} className={''} />
            <Grid item xs={false} sm={4} md={5} className={''} />
          </Grid>
          <NotificationContainer />
        </Grid>
      );
    }
  }
}
export default withStyles(styles, { withTheme: true })(Register);