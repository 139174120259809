import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-notifications/lib/notifications.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from './Router';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#03989e"
    },
    secondary: {
      main: "#ffcc80"
    }
  },
});
function App() {
  return (
   
    <ThemeProvider theme={theme}> 
      <Router>
        <Route component={Main} />
      </Router>
      </ThemeProvider>
  );
}

export default App;
