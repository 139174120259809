import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NotificationContainer } from 'react-notifications';

import { withStyles } from "@material-ui/core/styles";
const logo = process.env.PUBLIC_URL + 'logo.png';
const styles = theme => ({
    root: {
        height: '100vh',
        background: 'rgba(3,152,158,.1)',
    },
    image: {

    },
    mt: {
        marginTop: '20px',

    },
    paper: {
        // margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '32px 32px 64px 32px',
    },
    avatar: {
        margin: theme.spacing(1),

    },
    logo: {
        height: '70px!important',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    a: {
        color: '#03989e!important'
    },
    bg: {
        background: '#03989e!important'
    }
});
class EmailRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            g_loader: true,
            code: null
        }
    }


    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        let code = query.get('code');
        this.setState({ code: code })
    }




    render() {
        const classes = this.props.classes;
        document.title = "Une erreur est survenue";
        return (

            <Grid container component="main" className={' justify-content-md-center text-center'}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square className="pt-only-lg">
                    <div className={classes.paper}>
                        <div className={'mb-4'}>
                            <Link to="/">
                                <img src={'/logo.png'} height={'50px'} alt="EXPERT IT LAB" className={logo} />
                            </Link>
                        </div>
                        {
                            this.state.code === 'ERS500' ?
                                <div className={"alert alert-danger p-2"}>
                                    <h4>Oups ! Une erreur est survenue.</h4>
                                    <hr />
                                    <p>Ceci est sûrement due à une maintenance en cours ou une erreur inconnue. Essayer de vous déconnecter et vous reconnecter à nouveau. Si le problème persiste, veuillez nous contacter.</p>
                                </div>
                                : ''
                        }
                        {
                            this.state.code === 'ERS501' ?
                                <div className={"alert alert-danger p-2"}>
                                    <h4>Oups ! Une erreur est survenue.</h4>
                                    <hr />
                                    <p>Essayer de vous déconnecter et vous reconnecter à nouveau car cette action n'est sûrement pas autorisée. Si le problème persiste, veuillez nous contacter.</p>
                                </div>
                                : ''
                        }




                        <Grid container >

                            <p className='text-center p-3'>


                                <a href="https://account.expertitlab.com/login" className="btn">Nouvelle tentative</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <a href="https://admin.expertitlab.com/fr/logout-account" className="btn">Se déconnecter</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <a href="https://expertitlab.com/fr/get-help" target="_blank" rel="noreferrer" className="btn">Signaler une erreur</a>{' '}

                            </p>
                        </Grid>
                        <Box mt={0}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                L'expertisme numérique à votre portée ! <br />
                                {'Copyright © '}
                                <a href="https://expertitlab.com">
                                    EXPERT IT LAB
                                    </a>{' '}
                                {new Date().getFullYear()}
                                {'.'}
                            </Typography>
                        </Box>
                    </div>
                    <Grid item xs={false} sm={4} md={2} className={''} />
                    <Grid item xs={false} sm={4} md={5} className={''} />
                </Grid>
                <NotificationContainer />
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(EmailRequest);