import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { API_SERVER } from '../Api/Server.js';
import axios from 'axios';

import { withStyles } from "@material-ui/core/styles";
const logo = process.env.PUBLIC_URL + 'logo.png';
const styles = theme => ({
  root: {
    height: '100vh',
    background: 'rgba(3,152,158,.1)',
  },
  image: {

  },
  mt: {
    marginTop: '20px',

  },
  paper: {
    // margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '32px 32px 64px 32px',
  },
  avatar: {
    margin: theme.spacing(1),

  },
  logo: {
    height: '70px!important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  a: {
    color: '#03989e!important'
  },
  bg: {
    background: '#03989e!important'
  }
});
class EmailRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error_email: '',
      bool_email: false,
      bool_password: false,
      isl: false,
      btn_disabled: false,
      message: '',
      g_loader: true,

    }
  }

  email_request = (e) => {
    e.preventDefault();
    if (this.state.email === '') { this.setState({ error_email: 'Saisissez votre adresse email.', bool_email: true }) }

    if (this.state.email !== '') {
      this.setState({ btn_disabled: true });
      axios.post(API_SERVER + 'email/request', {
        email: this.state.email,
      })
        .then(response => {
          if (response.data.status === "success") {
            this.setState({ isl: true, message: response.data.message, email: '' });
            NotificationManager.success(response.data.data.message, 'Opération réussie!');

          }

          if (response.data.status === "error") {
            this.setState({ message: response.data.data.message })
            NotificationManager.error(response.data.data.message, 'Demande échouée!');
          }

          this.setState({ btn_disabled: false });

        })
        .catch((error) => {
          this.setState({ btn_disabled: false });
          NotificationManager.error('Une erreur est survenue! Réessayer plus tard.', 'Réesayer!');

        });
    }
  }

  handleChangeEmail = (val) => {
    if (val.target.value === '') {
      this.setState({ error_email: 'Saisissez votre adresse email.', bool_email: true, email: '' });
    }
    else {
      this.setState({ email: val.target.value, bool_email: false, error_email: '' });
    }
  }


  render() {
    const classes = this.props.classes;
    document.title = "Mot de passe oublié, EXPERT IT LAB";
    return (

      <Grid container component="main" className={' justify-content-md-center'}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square className="pt-only-lg">
          <div className={classes.paper}>
            <div className={''}>
              <Link to="/">
                <img src={'/logo.png'} height={'50px'} alt="EXPERT IT LAB" className={logo} />
              </Link>
            </div>
            <Typography component="h1" variant="h6">
              Récupération de mot de passe
            </Typography>

            <form className={classes.form} noValidate onSubmit={this.email_request}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Votre adresse email"
                name="email"
                autoComplete="email"
                error={this.state.bool_email}
                helperText={this.state.error_email}
                onChange={(val) => this.handleChangeEmail(val)}
                value={this.state.email}
              />
              <br />
              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit + classes.bg}
                id="register_btn"
                disabled={this.state.btn_disabled}
              >
                Recevoir le mail
              </Button>
              <Grid container >
                {
                  this.state.isl ?
                    <p style={{ color: "green", padding: '10px' }}> {this.state.message} </p>
                    : ''
                }
                <Grid item className={classes.mt} align="center">
                  <Link to="/register" variant="body2" className={classes.a} align="center">
                    {"Vous n'avez pas de compte? Inscrivez-vous"}
                  </Link>
                  <br />
                  <Link to="/login" variant="body2" className={classes.a}>
                    {"Se connecter?"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Typography variant="body2" color="textSecondary" align="center">
                  L'expertisme numérique à votre portée ! <br />
                  {'Copyright © '}
                  <a href="https://expertitlab.com">
                    EXPERT IT LAB
                </a>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </Box>
            </form>
          </div>
          <Grid item xs={false} sm={4} md={2} className={''} />
          <Grid item xs={false} sm={4} md={5} className={''} />
        </Grid>
        <NotificationContainer />
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EmailRequest);