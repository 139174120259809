import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withStyles } from "@material-ui/core/styles";
import {
  API_SERVER, EITLAB_TOKEN, EITLAB, ADMIN_URL, COOKIES_PATH, COOKIES_DOMAIN
} from '../Api/Server.js';
import EitlabLoader from '../Api/EitlabLoader';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const logo = process.env.PUBLIC_URL + 'logo.png';
const styles = theme => ({
  root: {
    height: '100vh',
    background: 'rgba(3,152,158,.1)',
  },
  image: {

  },
  mt: {
    marginTop: '20px',

  },
  paper: {
    // margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '32px 32px 64px 32px',
  },
  avatar: {
    margin: theme.spacing(1),

  },
  logo: {
    height: '70px!important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  a: {
    color: '#03989e!important'
  },
  bg: {
    background: '#03989e!important'
  }
});
class ValidationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error_code: '',
      bool_code: false,
      isl: false,
      btn_disabled: false,
      ril: false,
      mail_resend: false,
      mail_resend_message: '',
      mail_msg_text: 'Recevoir un nouveau code?',
      g_loader: true,
    }
  }

  componentDidMount() {
    axios.get(API_SERVER + 'confirm/validation-code/' + this.props.match.params.token + '@' + this.props.match.params.eitlab_id, {
      code: this.state.code,
    })
    .then(response => {
      if (response.data.status === "success") {
        
        if (!response.data.data.validate) {
          this.setState({ g_loader: false })
            window.location.href = "/login"
          }
          if (response.data.data.validate) {
            this.setState({ g_loader: false })
          }
        }
        else {
          window.location.href = "/login"
        }

      })
      .catch((error) => {
        window.location.href = "/login"
      });

  }
  confirm_code = (e) => {
    e.preventDefault();
    if (this.state.code === '') { this.setState({ error_code: 'Saisissez le code reçu par code', bool_code: true }) }

    if (this.state.code !== '') {
      this.setState({ btn_disabled: true });
      axios.post(API_SERVER + 'confirm/validation-code/' + this.props.match.params.token + '@' + this.props.match.params.eitlab_id, {
        code: this.state.code,
      })
        .then(response => {
          if (response.data.status === "success") {
            cookies.set('eitlab', response.data.data.data.eitlab_id, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
            cookies.set('eitlab_isl', true, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
            cookies.set('eitlab_tk', response.data.data.data.token, { path: COOKIES_PATH, domain: COOKIES_DOMAIN  });
            cookies.set('ad', response.data.data.data.rule, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
            window.location.href = ADMIN_URL;

          }

          if (response.data.status === "error") {

            let errors = response.data.message;
            NotificationManager.error(errors, 'Votre code ne correspond pas!');

          }

          this.setState({ btn_disabled: false });

        })
        .catch((error) => {
          this.setState({ btn_disabled: false });
          NotificationManager.error('Erreur de validation de code.', 'Réesayer!');

        });
    }
  }

  handleChangecode = (val) => {
    if (val.target.value === '') {
      this.setState({ error_code: 'Saisissez le code reçu par code.', bool_code: true, code: '' });
    }
    else {
      this.setState({ code: val.target.value, bool_code: false, error_code: '' });
    }
  }

  resendConfirmCode = () => {
    this.setState({ mail_msg_text: 'En cours d\'envoi..' })

    axios.get(API_SERVER + 'new/validation-code/' + EITLAB_TOKEN + '_' + EITLAB, {
      code: this.state.code,
    })
      .then(response => {
        if (response.data.status === "success") {
          this.setState({ mail_resend: true, mail_resend_message: response.data.message, mail_msg_text: "Envoyé!" });
        }
        if (response.data.status === "error") {

          let errors = response.data.message;
          NotificationManager.error(errors, 'Nous ne parvenons pas à éffectuer votre demande.');

        }
        this.setState({ btn_disabled: false });

      })
      .catch((error) => {
        this.setState({ btn_disabled: false });
        NotificationManager.error('Erreur d\'envoi de mail.', 'Réesayer!');

      });
  }
  render() {
    const classes = this.props.classes;
    document.title = "Validation de compte par code, EXPERT IT LAB";
    if (this.state.g_loader) {
      return (

        <div style={{ textAlign: 'center', paddingTop: '50vh', height: '100vh', }}>
          <EitlabLoader loader_color="#03989e" />
        </div>
      )

    }
    else {
      return (

        <Grid container component="main" className={' justify-content-md-center'}>
          <CssBaseline />
          <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square className="pt-only-lg">
            <div className={classes.paper}>
              <div className={''}>
                <Link to="/">
                  <img src={'/logo.png'} alt='EXPERT IT LAB' height={'50px'} className={logo} />
                </Link>
              </div>
              <Typography component="h1" variant="h6">
                Validation de compte
            </Typography>

              <form className={classes.form} noValidate onSubmit={this.confirm_code}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  inputProps={{ minLength: 5, maxLength: 5 }}

                  required
                  fullWidth
                  id="code"
                  label="Votre code de validation"
                  name="code"
                  autoComplete="code"
                  error={this.state.bool_code}
                  helperText={this.state.error_code}
                  onChange={(val) => this.handleChangecode(val)}
                  value={this.state.code}
                />
                <br />
                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit + classes.bg}
                  id="register_btn"
                  disabled={this.state.btn_disabled}
                >
                  VALIDER
              </Button>
                <Grid container >

                  <Grid item className={classes.mt} align="center">
                    <button type="button" onClick={() => this.resendConfirmCode()} variant="body2" className={classes.a} align="center">
                      {this.state.mail_msg_text}
                    </button>
                  &nbsp;&nbsp;&nbsp;
                  <Link to="/login" variant="body2" className={classes.a}>
                      {"Se connecter?"}
                    </Link>
                    {
                      this.state.mail_resend ?
                        <p style={{ color: 'green', padding: '3px' }}>{this.state.mail_resend_message}</p>
                        : ''
                    }
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Typography variant="body2" color="textSecondary" align="center">
                    L'expertisme numérique à votre portée ! <br />
                    {'Copyright © '}
                    <a href="https://expertitlab.com">
                      EXPERT IT LAB
                </a>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                  </Typography>
                </Box>
              </form>
            </div>
            <Grid item xs={false} sm={4} md={2} className={''} />
            <Grid item xs={false} sm={4} md={5} className={''} />
          </Grid>
          <NotificationContainer />
        </Grid>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(ValidationCode);